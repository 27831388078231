
import { Document, Page, Text, View, StyleSheet,Image } from '@react-pdf/renderer';
import { Table,TableRow,TableCell,TableHeader } from '@ag-media/react-pdf-table';
import Drapeau from "../photos/drapeau.png"
import Embleme from "../photos/embleme.png" 

const Tableau=({total2,annee_scolaire,num_permanent,sexe,My_ecole,dataclasse,key,nom,datenaissance,lieunaissance,recordseleves,dataeleve,classe,totauxgen,maximum,totaux,place,cotes,total,cours,mes_cours,eleve,eleves,idclasse,isEmpty,getponderation})=>{
  const styles=StyleSheet.create({
    page:{
      flexDirection:"row",
      border:"3px  black solid",
    },
    section:{
     paddingHorizontal:"10px",
     paddingVertical:"10px",
      width:"100%",
      border:"1px solid black",
      justifyContent:"start",
      
      
      
    },
    theader:{
      fontWeight:'bold',
      fontSize:10,
      textAlign:"center"
    
    },
    othertext:{
      fontSize:12,
      textAlign:"left",
      width:"100%",
      display:"block",
      flex:1,
    },
    texta:{
      fontSize:12,
      textAlign:"center",
      width:"100%",
      display:"block",
      flex:1,
    },
    noborder:{
      borderStyle:"none",
    },
    tbranche:{
      width:"55%"
    },
    tother:{
      width:"5%"
    }
  
  })
      
  return(
    <Page size="A4" style={styles.page} wrap={false}>
      <View style={styles.section}>
        <Table weightings={[0.25,0.0625,0.0625,0.0625,0.0625,0.0625,0.0625,0.0625,0.0625,0.0625,0.0625,0.0625,0.0625]}>
            <TableRow >
              <TableCell weighting={0.15} style={{borderRight:0,borderBottom:0,marginBottom:2}}>
              <Image src={Drapeau} style={{position:'absolute',width:'90%',top:5,right:5,height:"auto",bottom:1}} />
              </TableCell>
              <TableCell style={{borderLeft:0,borderRight:0,borderBottom:0}} weighting={0.7}>
                  <Text style={styles.texta}>
                    REPUBLIQUE DEMOCRATIQUE DU CONGO {"\n"}
                    MINISTERE DE L'ENSEIGNEMENT PRIMAIRE, SECONDAIRE ET {"\n"} INITIATION A LA NOUVELLE CITOYENNETE
                  </Text>
                
              </TableCell>
              <TableCell style={{borderLeft:0,borderBottom:0}} weighting={0.15}>
                <Image src={Embleme} style={{position:'absolute',width:'80%',top:5,right:5,height:"auto",bottom:1}} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell weighting={1}  style={{flexDirection:"column"}}>
                <Text style={styles.othertext}>
                  N°ID : 
                
                      <Table>
                          <TableRow>
                            <TableCell >.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                          </TableRow>
                      </Table>
                </Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell weighting={1}>
                <Text style={styles.othertext}>
                  PROVINCE DE {!isEmpty(My_ecole) && My_ecole?.province?.toUpperCase()}
                </Text>
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell weighting={0.5}>
                <Text style={styles.othertext}>
               VILLE :{!isEmpty(My_ecole) && My_ecole?.ville?.toUpperCase()}{"\n"}
               COMMUNE :{!isEmpty(My_ecole) && My_ecole?.commune?.toUpperCase()}{"\n"}
               ECOLE :{!isEmpty(My_ecole) && My_ecole?.nom?.toUpperCase()}{"\n"}
               CODE :{!isEmpty(My_ecole) && My_ecole?.code?.toUpperCase()}{"\n"}
               </Text>
              </TableCell>
                <TableCell weighting={0.5}>
                <Text style={styles.othertext}>
                  ELEVE : {!isEmpty(nom) && nom?.toUpperCase()} {"\n"}SEXE : {!isEmpty(sexe) && sexe?.toUpperCase()} {"\n"}
                  NE (E) A: {!isEmpty(datenaissance) && lieunaissance?.toUpperCase()+" LE "+datenaissance} {"\n"}
                  CLASSE : {!isEmpty(dataclasse) && dataclasse[0]?.nom?.toUpperCase()} {"\n"}
                  N° PERM : {!isEmpty(num_permanent) && num_permanent?.toUpperCase()} {"\n"}
                </Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell weighting={1}>
                <Text style={styles.texta}>
                  BULLETIN DE LA {!isEmpty(dataclasse) && dataclasse[0]?.maclasse?.toUpperCase()} {"\t"} ANNEE SCOLAIRE {!isEmpty(annee_scolaire) && annee_scolaire}
                </Text>
              </TableCell>
            </TableRow>
            <TableRow style={styles.theader} >
              <TableCell >
                BRANCHES
              </TableCell>
              <TableCell >
                1ère P
              </TableCell>
              <TableCell >
                2ème P
              </TableCell>
               <TableCell>
                EXAM
              </TableCell>
               <TableCell>
                TOT.
              </TableCell>
               <TableCell>
                3ème P
              </TableCell>
               <TableCell>
                4ème P
              </TableCell>
              <TableCell>
                EXAM
              </TableCell>
               <TableCell>
                TOT.
              </TableCell>
               <TableCell >
                TOT.GEN
              </TableCell>
              <TableCell >
               
              </TableCell>
              <TableCell >
                
              </TableCell>
              <TableCell >
                
              </TableCell>
            </TableRow>
        </Table>
      </View>
    </Page>
  )
}
export default Tableau