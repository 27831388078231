import axios from "../../../../../api/axios";
import { useEffect, useState } from "react";
import { useNavigate,Link } from "react-router-dom";
import {isEmpty} from '../../../../../Utilities/Utils'
function Fiche(){

    const [eleves,setEleves]=useState([])
    const [classes,setClasses]=useState([])
    const [cotes,setCotes]=useState([])
    const [ecole,setEcole]=useState([])
    const [cours,setCours]=useState([])
    const [evaluations,setEvaluations]=useState([])
    const [ponderation,setPonderation]=useState("")
    const [periode,setPeriode]=useState("")
    const navigate=useNavigate()
    const [detailcotes,setDetailcotes]=useState([])
    const [loading,setLoading]=useState(false)
    const [date_evaluations,setDate_evaluations]=useState()
    const [classe,setClasse]=useState("")
    const [cour,setCour]=useState("")
    const recordcours=cours.filter((cr)=>cr.idclasse==classe && cr.idenseignant==localStorage.getItem("idenseignant"))
    const coursencours=cours.filter((cr)=>cr.id==cour)
    const recordevaluations=!isEmpty(periode) ? evaluations.filter((evaluation)=>evaluation.idclasse==classe && evaluation.idcour==cour && evaluation.periode==periode) : evaluations.filter((evaluation)=>evaluation.idclasse==classe && evaluation.idcour==cour)
    const recordseleves=eleves.filter((eleve)=>eleve.idclasse==classe)
    let total=0
    let totalmax=0
    let summ=0
    let maximum=0
    const dateformate=(mydate)=>{
    const [year, month, day] = mydate.split('-');
    const formattedDate = `${day}/${month}`;
    
    return formattedDate
}
    
   
useEffect(()=>{
    HandleRequest()
    elevesrequest()
    coursRequest()
    handleallcotes()
},[])
const HandleRequest=async()=>{
    await axios.post('api/classes_getter',{idecole:localStorage.getItem("idecole")}).then((res)=>{
        setClasses(res.data.items)
        setEcole(res.data.ecole)
    }).catch((err)=>{
        
    })
}
const coursRequest=async()=>{
    await axios.post('api/getcours',{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
        setCours(res.data.items)
    }).catch((err)=>{
        
    })
}
const elevesrequest=async()=>{
    setLoading(true)
    await axios.post("api/allinscription",{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
        setEleves(res?.data?.items)
        setLoading(false)
    }).catch((err)=>{

    })
}
const handleallcotes=async()=>{
    await axios.post('api/getallcotes',{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire"),idenseignant:localStorage.getItem("idenseignant")}).then((res)=>{
        setCotes(res?.data?.items)
        setEvaluations(res?.data?.evaluations)
        setDetailcotes(res?.data?.detailcotes)
        
    }).catch((err)=>{

    })
}

const moyenne=(total,maximum,ponderation)=>{
    const pond=(periode=="Examen1" || periode=="Examen2") ? ponderation*2 : ponderation
    return parseInt(total/maximum*pond)
}
const somme=(point)=>{
   summ=summ+point
   return summ
}
const reinitialisation=()=>{
    summ=0
}
const maximumEval=()=>{
    recordevaluations?.length>0 &&
      recordevaluations.map((rec)=>
      {
          maximum=maximum+rec.ponderation
      }
      )
  return maximum
  }
    return(
        <>
        <div className="container">
            <div className="row justify-content-start">
            <div className="col-md-4 mb-2">
                    <div className="form-outline">
                        <label htmlFor="" className="form-label">Classe</label>
                        <select name="classe" id="classe" className="form-select form-select-lg" onChange={(e)=>setClasse(e.target.value)} value={classe}>
                            <option value="">Seléctionner la classe</option>
                            {
                                classes.map((clas)=>
                                    <option value={clas.id}>{clas.nom}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className="col-md-4 mb-2">
                    <div className="form-outline">
                        <label htmlFor="" className="form-label">Cours</label>
                        <select name="cours" id="cours" className="form-select form-select-lg" onChange={(e)=>setCour(e.target.value)} value={cour}>
                            <option value="">Seléctionner le cours</option>
                            {
                                recordcours.map((cr)=>
                                    <option value={cr.id}>{cr.cours}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className="col-md-4 mb-2">
                    <div className="form-outline">
                        <label htmlFor="" className="form-label">Période</label>
                        <select name="periode" id="periode" className="form-select form-select-lg" onChange={(e)=>setPeriode(e.target.value)} value={periode}>
                            <option value="">Seléction de la période</option>
                            <option value="P1">Première période</option>
                            <option value="P2">Deuxième période</option>
                            <option value="Examen1">Examen Ier Semestre</option>
                            <option value="P3">Troisième période</option>
                            <option value="P4">Quatrième période</option>
                            <option value="Examen2">Examen 2nd Semestre</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row justify-content-start">
            <div className="table-responsive">
                <table className="table table-bordered table-responsive">
                    <thead>
                        <tr>
                            <th className="sticky-col">#</th>
                            <th className="sticky-col">Nom & postnom</th>
                            {
                                recordevaluations.map((evaluation)=>{totalmax=somme(parseInt(evaluation.ponderation))
                                    return(
                                        <>
                                          <th>{dateformate(evaluation.date_evaluation) }<br/>{"Max:"+evaluation.ponderation}</th>
                                        </>
                                    )
                                }
                                  
                                )
                             
                            }
                            <th>Total évaluation <br/> /{maximumEval()} </th>
                            <th>Moyenne périodique <br /> /{(periode=="Examen1" || periode=="Examen2") ? coursencours?.[0]?.ponderation*2 : coursencours?.[0]?.ponderation} </th>
                            {reinitialisation()}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            recordseleves.map((elev,index)=>
                                   {
                                    return(
                                        <>
                                            {
                                                 <tr key={index}>
                                                 <td className="sticky-col">{index+1}</td>
                                                 <td className="sticky-col">{elev.nom_complet}</td>
                                                 {
                                                 recordevaluations.map((evaluation)=>
                                                 {
                                                     return(
                                                         <>
                                                         {
                                                              detailcotes.filter((detail)=>detail.idinscription==elev.id && detail.idcour==cour && detail.date_evaluation==evaluation.date_evaluation && detail.created_at==evaluation.created_at).map((det)=>{total=somme(parseInt(det.point))
                                                                 return(
                                                                     
                                                                     <>
                                                                         <td>{det.point}</td>
                                                                     </>
                                                                 )
                                                             })
                                                         }
                                                        
                                                         </>
                                                     )
                                                 }
                                                 )}
                                                  <td>{total}</td>
                                                 
                                                  <td>{ !isEmpty(periode) && moyenne(parseInt(total),parseInt(totalmax),parseInt(coursencours?.[0]?.ponderation))}</td>
                                                  {reinitialisation()}
                                             </tr>
                                           
                                             }
                                                                           
                                        </>
                                    )
                            
                                })
                        }
                    </tbody>
                </table>
            </div>
            <div className="row justify-content-start">
                <div className="col-md-3 mb-2">
                        <div className="form-outline">
                        <Link to={`${classe}/${cour}/${periode}`} className="btn btn-success btn-lg">Download Fiche de cote </Link>
                        </div>
                </div>
                
                <div className="col-md-3 mb-2">
                            <div className="form-outline">
                                <Link to={`/listeproclamation/${classe}/${periode}`}>
                                    <button className="btn btn-success btn-lg">Liste proclamation</button>
                                </Link>
                            </div> 
                        </div>
            </div>
            </div>
        </div>
        </>
    )
}

export default Fiche;